import React from 'react';
import PropTypes from 'prop-types';
import { sum } from 'lodash';
import { Form, Grid, Loader, Message, Divider, Button } from 'common/lazy';
import { formatApiDate, parseApiDate } from 'utils/api';
import { Component } from 'common/helpers';
import {
  Layout,
  FormActions,
  IntegerInput,
  DatePicker,
  SVGIcon as Icon,
  ContentfulRichText,
  getContentfulField,
  DesktopOnly,
  MobileOnly,
  ContentfulMarkdown,
} from 'common/components';
import {
  validateInventory,
  hasValidQuantity,
  isSoldOut,
  getQuantity,
  getItemName,
  getItemPrice,
  getItemTax,
  trackProductViewed,
  addonsCount,
  ticketSalesClosed,
} from 'common/helpers';
import { ErrorMessage } from '../ErrorMessage';
import { Spacer } from '../Spacer';
import { Totals } from '../Totals';
import { inject, observer } from 'mobx-react';
import { formatCurrency } from 'utils/l10n';
import { scrollIntoView } from 'utils/helpers/scroll';
import { isDateChangeFee, isWristbandProduct } from 'public/helpers/functions';

import './ticket-selector.less';

const DEFAULT_TIMESLOT_TITLE = 'Timeslot Selection';

@inject('ticketInventory', 'cart', 'externalbookings')
@observer
export default class TicketSelector extends Component {
  ticketTypeRef = null;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      reservationDate: parseApiDate(this.props.reservationDate),
      quantities: {},
      loading: true,
      displayAddOns: false,
      ticketsAddedToCart: [],
      startTime: null,
      validExternalBookings: true,
    };
  }

  componentDidMount() {
    this.fetchInventory();
  }

  componentDidUpdate(lastProps, lastState) {
    if (lastState.reservationDate !== this.state.reservationDate) {
      this.setState({
        quantities: {},
      });
      this.fetchInventory();
    }

    if (
      lastState.sessions !== this.state.sessions &&
      this.state.sessions?.length == 1
    ) {
      this.onChangeSession(this.state.sessions[0].startTime);
      this.props.onChangeSession(this.state.sessions[0]);
    }
  }

  getSessions() {
    const { venue } = this.props;
    const { reservationDate } = this.state;
    const sessions = this.props.ticketInventory.getSessions(
      venue.id,
      reservationDate
    );

    const sortedSessions = sessions?.sort(
      (a, b) =>
        Number(a.startTime.replace(':', '')) -
        Number(b.startTime.replace(':', ''))
    );

    return sortedSessions;
  }

  async fetchInventory() {
    try {
      const { venue } = this.props;
      const { reservationDate } = this.state;

      await this.props.ticketInventory.search({
        venueId: venue.id,
        date: reservationDate,
        slug: venue.slug,
        forceRefresh: true,
      });

      this.props.ticketInventory
        .get(venue.id, reservationDate)
        .filter(
          (product) =>
            !product.addon &&
            !isDateChangeFee(product.name) &&
            !isWristbandProduct(product.name)
        )
        .forEach((inventoryItem) => {
          trackProductViewed(inventoryItem, venue);
        });

      const sessions = this.getSessions();

      this.setState({
        hasSessions: Array.isArray(sessions),
        sessions,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }

  validate(inventory) {
    const { venue, externalbookings } = this.props;
    const { reservationDate, quantities } = this.state;
    if (ticketSalesClosed(venue, reservationDate)) {
      return {
        canSubmit: false,
        errorMessage: 'Please select a date in the future',
        errorTitle: 'Same-day ticket sales are now closed.',
      };
    }

    return validateInventory(
      inventory,
      quantities,
      venue,
      externalbookings.venueAvailabilities
    );
  }

  hasValidQuantity(item, inventory) {
    const { venue } = this.props;
    const { quantities } = this.state;
    return hasValidQuantity(item, inventory, quantities, venue);
  }

  getSoldOutLabel(item) {
    return parseApiDate(item.date) < new Date()
      ? 'No Longer Available'
      : 'Sold Out';
  }

  getQuantity(item) {
    return getQuantity(item, this.state.quantities);
  }

  getTicketCount(inventory) {
    return sum(inventory.map((item) => this.getQuantity(item)));
  }

  getSubtotal(inventory) {
    const { quantities } = this.state;
    return sum(
      inventory.map((item) => {
        const quantity = this.getQuantity(item);
        return getItemPrice(item, inventory, quantities) * quantity;
      })
    );
  }

  getTax(inventory) {
    const { quantities } = this.state;
    return sum(
      inventory.map((item) => {
        const quantity = this.getQuantity(item);
        return getItemTax(item, inventory, quantities) * quantity;
      })
    );
  }

  setQuantity(item, quantity) {
    const { quantities } = this.state;

    const quantityToUse = this.state.hasSessions
      ? { startTime: this.state.startTime, quantity }
      : quantity;

    quantities[item.ticketOptionId] = quantityToUse;

    this.setState({
      quantities,
    });
  }

  onDayChange = (reservationDate) => {
    this.setState({
      reservationDate,
      startTime: null,
    });
  };

  onChangeSession = (startTime) => {
    this.setState({
      startTime,
      quantities: {},
    });
  };

  onSubmit = (inventory) => {
    const action = this.getAction();
    const { venue } = this.props;
    const reservationDate = formatApiDate(this.state.reservationDate);
    const startTime = this.state.startTime;
    const session =
      startTime && this.state.sessions.find((s) => s.startTime === startTime);
    const startTimeName = session && session.name;

    const tickets = inventory.map((item) => {
      const {
        ticketOptionId,
        bookingItemId,
        externalId,
        name,
        date,
        price,
        tax,
      } = item;
      return {
        name,
        date,
        price,
        venueId: venue.id,
        venueName: venue.name,
        externalId,
        bookingItemId,
        ticketOptionId,
        reservationDate,
        startTime,
        startTimeName,
        quantity: this.getQuantity(item),
        tax,
      };
    });
    this.props.onSubmit(tickets, action);
  };

  getAction() {
    const el = document.activeElement;
    return (el && el.type === 'submit' && el.name) || null;
  }

  handleSessionClick = (session) => {
    return () => {
      this.onChangeSession(session.startTime);
      this.props.onChangeSession(session);
      scrollIntoView(this.ticketTypeRef);
    };
  };

  render() {
    const { venue } = this.props;

    const inventory = this.props.ticketInventory
      .get(venue.id, this.state.reservationDate)
      ?.filter(
        (product) =>
          !product.addon &&
          !isDateChangeFee(product.name) &&
          !isWristbandProduct(product.name)
      );

    if (!inventory || this.state.loading) {
      return (
        <React.Fragment>
          <Spacer size="l" />
          <Loader size="large" inline="centered" active />
          <Spacer size="l" />
        </React.Fragment>
      );
    }

    const { canSubmit, errorMessage, errorTitle } = this.validate(inventory);

    return (
      <div {...this.getAttrs()}>
        <Form
          onSubmit={() => this.onSubmit(inventory)}
          className={this.getElementClass('form')}>
          <Layout
            horizontal
            stackable
            className={this.getElementClass('layout')}>
            <Layout.Group className={this.getElementClass('tickets')}>
              <Spacer size="s" />
              <h4>Purchase Tickets</h4>
              <Spacer size="s" />
              <Layout vertical>
                <Layout.Group>
                  <label htmlFor="date" className="date-label">
                    Select a Date
                  </label>
                  <Spacer size="xs" />
                </Layout.Group>
                {venue?.content?.datePickerDescription && (
                  <ContentfulMarkdown
                    field={venue.content.datePickerDescription}
                  />
                )}
                <Spacer size="xs" />
                <Layout.Group grow>
                  <div className="btn-wrapper">
                    <DatePicker
                      icon={<Icon size="tiny" name="calendar" />}
                      date={this.state.reservationDate}
                      onDayChange={this.onDayChange}
                      startDate={new Date()}
                    />
                  </div>
                </Layout.Group>
              </Layout>
              <Spacer size="s" />
              {this.renderSessions(venue)}
              <div
                ref={(ref) => {
                  this.ticketTypeRef = ref;
                }}>
                <Divider className="light" />
                <h5 htmlFor="ticketType">Ticket Type</h5>
                <Spacer size="s" />
                <div>
                  {this.renderTickets(inventory)}
                  <MobileOnly>
                    <Spacer size="s" />
                    <Divider className="light" />
                    <h5 htmlFor="cost">Cost</h5>
                    <Spacer size="xs" />
                    <Totals
                      count={this.getTicketCount(inventory)}
                      subtotal={this.getSubtotal(inventory)}
                      tax={this.getTax(inventory)}
                    />
                    {this.renderError(errorMessage, errorTitle)}
                    <FormActions>
                      {this.props.renderActions(canSubmit)}
                    </FormActions>
                  </MobileOnly>
                </div>
              </div>
              {this.renderExtraContent(venue)}
            </Layout.Group>
            <Layout.Group className={this.getElementClass('totals')}>
              <DesktopOnly>
                <Spacer size="m" />
                <Totals
                  count={this.getTicketCount(inventory)}
                  subtotal={this.getSubtotal(inventory)}
                  tax={this.getTax(inventory)}
                />
                {this.renderError(errorMessage, errorTitle)}
                <Spacer size="xs" />
                <FormActions>{this.props.renderActions(canSubmit)}</FormActions>
                <Spacer size="m" />
              </DesktopOnly>
            </Layout.Group>
          </Layout>
        </Form>
      </div>
    );
  }

  renderExtraContent(venue) {
    const { alerts, terms, ticketInfo } = venue.content || {};
    return (
      <>
        {alerts && (
          <>
            <Divider className="light" />
            <ContentfulRichText field={alerts} small />
          </>
        )}
        {ticketInfo && (
          <>
            <Divider className="light" />
            <ContentfulRichText field={ticketInfo} small />
          </>
        )}
        {terms && (
          <>
            <Divider className="light" />
            <ContentfulRichText field={terms} small />
          </>
        )}
        <Spacer size="s" />
      </>
    );
  }

  renderError(errorMessage, errorTitle) {
    if (errorMessage) {
      return (
        <React.Fragment>
          <Spacer size="s" />
          <ErrorMessage header={errorTitle} content={errorMessage} />
        </React.Fragment>
      );
    }
  }

  renderTicketSelectorNote(inventory, quantities) {
    const note = this.props.venue?.content?.addonNote;
    if (note && addonsCount(inventory, quantities)) {
      return (
        <>
          <Spacer size="s" />
          <ContentfulRichText
            field={note}
            className={this.getElementClass('addon-note')}
          />
        </>
      );
    }
  }

  renderSessions(venue) {
    if (this.state.hasSessions) {
      const sessionSelectorTitle = venue?.content?.sessionSelectorTitle
        ? getContentfulField(venue.content.sessionSelectorTitle)
        : DEFAULT_TIMESLOT_TITLE;

      return (
        <>
          <Divider className="light" />
          <h5 htmlFor="title">{sessionSelectorTitle}</h5>
          <Spacer size="xs" />
          {venue?.content?.sessionSelectorDescription && (
            <div className={this.getElementClass('session-selector')}>
              <ContentfulMarkdown
                field={venue.content.sessionSelectorDescription}
              />
            </div>
          )}
          <Spacer size="xs" />
          <Layout horizontal wrap>
            {this.renderSessionOptions()}
          </Layout>
          <Spacer size="s" />
        </>
      );
    }
  }

  renderSessionOptions() {
    return this.state.sessions.map((session) => {
      const remaining = session.capacityRemaining;
      return (
        <Layout.Group key={session.startTime}>
          <Button
            as="div"
            type="button"
            key={session.startTime}
            size="small"
            className="session-slot"
            onClick={this.handleSessionClick(session)}
            disabled={!remaining}
            inverted={session.startTime === this.state.startTime}>
            <span>{session.name}</span>
            <span className={this.getElementClass('remaining')}>
              {!remaining && 'Sold out'}
            </span>
          </Button>
        </Layout.Group>
      );
    });
  }

  renderTickets(inventory) {
    if (
      !inventory.length ||
      (this.state.hasSessions && !this.state.sessions.length)
    ) {
      return <Message>No Tickets available</Message>;
    } else if (this.state.hasSessions && !this.state.startTime) {
      return <Message size="small">Select a time slot first</Message>;
    }
    const { quantities } = this.state;
    return (
      <>
        <Grid>
          {inventory.map((item, i) => {
            if (
              typeof item.quantity === 'object' &&
              !item.quantity[this.state.startTime]
            )
              return null;

            return (
              <Grid.Row key={i}>
                <Grid.Column computer={11} mobile={8}>
                  <Layout horizontal stackable spread>
                    <Layout.Group className={this.getElementClass('item-name')}>
                      {getItemName(item, inventory, quantities)}
                    </Layout.Group>
                    <Layout.Group
                      className={this.getElementClass('item-price')}>
                      {formatCurrency(
                        getItemPrice(item, inventory, quantities)
                      )}
                    </Layout.Group>
                  </Layout>
                </Grid.Column>
                <Grid.Column computer={5} mobile={8}>
                  {isSoldOut(item, this.state.startTime) ? (
                    <div className={this.getElementClass('sold-out')}>
                      {this.getSoldOutLabel(item)}
                    </div>
                  ) : (
                    <IntegerInput
                      error={!this.hasValidQuantity(item, inventory)}
                      disabled={this.state.hasSessions && !this.state.startTime}
                      value={this.getQuantity(item, quantities) || ''}
                      onChange={(quantity) => this.setQuantity(item, quantity)}
                      max={999}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
        {this.renderTicketSelectorNote(inventory, quantities)}
      </>
    );
  }
}

TicketSelector.propTypes = {
  venue: PropTypes.object,
  loadCartQuantities: PropTypes.bool,
  reservationDate: PropTypes.instanceOf(Date),
  onSubmit: PropTypes.func.isRequired,
  renderActions: PropTypes.func.isRequired,
  onChangeSession: PropTypes.func,
};

TicketSelector.defaultProps = {
  loadCartQuantities: false,
  onChangeSession: null,
};
